<!--
 * @Date: 2022-03-25 16:27:21
 * @LastEditTime: 2024-08-21 10:31:47
 * @Description:
 * @FilePath: \szlsnk-user-mp\src\views\user\imageReport\ftReportDetail.vue
-->
<template>
  <div style="width: 100%; height: 100%">
    <iframe
      name="ft-iframe"
      class="iframe"
      :src="url"
      frameborder="0"
    />
  </div>
</template>

<script>
  export default {
    name: 'FtReportDetail',
    watchQuery: true,
    data() {
      return {
        url: ''
      }
    },
    mounted() {
      this.url = this.$route.query.url
      // console.log('this.url', this.url)
    },
    methods: {
    }
  }
</script>

<style scoped lang="less">
.iframe {
  height: 100%;
  width: 100%;
}

</style>
